export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--tertiary',

  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_CONTAINER_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',

  SEARCH_BOX_BUTTON_CLASS: 'search__button field__button',

  DISCARD_VEHICLE: 'Clear',
  CHANGE_VEHICLE: 'Change',

  GARAGE_SIZE:
    '<span class="garage-size cart-count-bubble with with__count" key="garage-size">{{size}}</span>',
};
