import shopifyGenericDefaults from '../shopify-generic/config.js';

shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchVehicleWidget').columnBreakpoint = 650;
shopifyGenericDefaults.Widgets.find((w) => w.name === 'VerifyFitment').isAlwaysColumnLayout = true;

const wheelsFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  SearchRequestDefaults: {
    ...shopifyGenericDefaults.SearchRequestDefaults,
    extra: {
      ...shopifyGenericDefaults.SearchRequestDefaults.extra,
      doNotHideWheelsTiresFacets: window.location.pathname === '/',
    },
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets,
    {
      name: 'HomeVehicleWidget_tab',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-home-ymm',
        class: 'cm_vehicle-widget__tabs',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 650,
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: {
        selector: '#cm-home-wheels',
        class: 'cm_vehicle-widget__tabs',
      },
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      redirectUrl: 'collections/wheels',
      selectFields: wheelsFields,
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: {
        selector: '#cm-home-tire',
        class: 'cm_vehicle-widget__tabs',
      },
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      redirectUrl: '/collections/tires',
      selectFields: tiresFields,
    },
    {
      name: 'BrandPanel_tab',
      type: 'RequestPanel',
      location: {
        selector: '#cm-home-brand',
        class: 'cm_vehicle-widget__tabs',
      },
      template: 'HomeBrandBar',
      selectFields: ['vendor'],
      needInitRequest: true,
    },
  ],
};
